// Menu

.hb-menu
  display: flex
  justify-content: flex-end
  align-items: center
  width: 75%
  height: 40px
  .btn
    &--premium
      +v-sm
        width: 100%
        height: 60px
        margin-left: 0
        border-radius: 0
        font-size: 1.25em
  +v-sm
    position: fixed
    display: block
    top: 80px
    left: 120vw
    right: 0
    width: 100%
    height: 100vh
    background-color: $dark
    transition: all ease-out .4s
    &.mob-menu
      left: 0
  &__link
    display: inline-flex
    align-items: center
    height: 40px
    margin-left: 48px
    color: $white
    font-size: 1em
    font-weight: 600
    +v-sm
      display: block
      width: 100%
      height: 60px
      margin-left: 0
      padding: 0 24px
      color: $premium
      font-size: 1em
      line-height: 58px
      align-items: center
      border-top: 1px solid darken($dark,5%)
    &:hover,
    &.active
      color: $blue

$lato: 'Lato', sans-serif
$mate: 'Mate SC', sans-serif
$mont: 'Montserrat', sans-serif
$open: 'Open Sans', sans-serif
$pop: 'Poppins', sans-serif
$robot: 'Roboto', sans-serif
$meri: 'Merienda One', sans-serif
