// Statistics

.statistic
  display: flex
  flex-flow: row wrap
  justify-content: space-between
  margin-top: 60px
  padding: 60px 0
  background: url(../../assets/img/earth-map.svg) center center no-repeat
  background-size: cover
  +v-sm
    padding: 0 0 40px
    flex-flow: column nowrap
  &__card
    display: flex
    flex-flow: row wrap
    align-items: center
    margin-left: 5%
    margin-right: 60px
    margin-bottom: 48px
    padding: 10px 40px 10px 24px
    background-color: $white
    box-shadow: $shadow-10
    border-radius: 16px
    +v-md
      margin-left: 3%
      margin-right: 20px
      padding: 10px 16px 10px 24px
    +v-sm
      width: 75%
      margin: 24px auto 0
      padding-left: 30px
    +v-xs
      width: 100%
      flex-flow: column nowrap
      justify-content: center
      padding: 10px 0 0
    &.right
      margin-left: 60px
      margin-right: 5%
      +v-md
        margin-left: 3%
        margin-right: 20px
      +v-sm
        margin-left: auto
        margin-right: auto
  &__left,
  &__right
    display: flex
    justify-content: center
    align-items: center
    width: 50%
    +v-sm
      width: 60%
      margin: 0 auto
  &__right
    width: 42%
    margin-top: 3%
    margin-right: 5%
    flex-flow: column nowrap
    +v-md
      margin-top: 1%
      margin-bottom: -5%
    +v-sm
      width: 80%
      margin: 0 auto
  &__left
    img
      max-width: 100%
  &__title
    color: $premium
    font-size: 3.45em
    font-weight: 800
    +v-md
      font-size: 3.15em
    +v-xs
      font-size: 2.75em
  &__text
    width: 62%
    margin-left: 24px
    font-size: 1.25em
    font-weight: 500
    +v-md
      width: 59%
      font-size: 1.15em
    +v-xs
      width: 86%
      margin: 5px auto 15px
      text-align: center
