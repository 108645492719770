.btn
  display: inline-flex
  justify-content: center
  align-items: center
  width: auto
  height: 40px
  margin-left: 40px
  padding: 2px 24px 0
  font-size: .85em
  font-weight: 700
  letter-spacing: .05em
  text-transform: uppercase
  border-radius: 8px
  transition: all ease-out .3s
  background: linear-gradient(86.23deg, #AC5FF9 0%, #5F6FF9 101.28%)
  +v-sm
    zoom: .85
  &:hover
    background-color: $dark
  &--premium
    color: $white
    background-color: $premium
  &--big
    padding: 0 48px
    height: 60px
    font-size: 1em
