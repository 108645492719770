// CTA

#cta_block
  background: #333 url(../../assets/img/product-bg.jpg) center center no-repeat
  background-blend-mode: overlay
  background-size: cover
  +v-sm
    background-size: 120%
  +v-xs
    background-image: none
    background-color: $white

.cta
  width: 60%
  margin: 0 auto
  +v-sm
    width: 72%
    height: 280px
  +v-xs
    width: 88%
    margin: 0 auto
    height: auto
  &__text
    font-size: 1.25em
    line-height: 1.75
    +v-sm
      font-size: 1.025em
      line-height: 1.5
      margin-top: -10%
  &__link
    margin: 60px auto 80px
    +v-sm
      margin: 10px auto 40px
