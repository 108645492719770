// Features
#feature_block
  position: relative
  width: 100%
  height: auto
  min-height: 100vh
  background: url('../../assets/svg/f-top.svg') left top no-repeat
  background-size: 40%
  z-index: 1
  .container
    position: relative
    z-index: 2
  &::after
    bottom: 0
    left: 0
    position: absolute
    display: block
    width: 120%
    height: 120vh
    content: ''
    background: url('../../assets/svg/f-bl.svg') left bottom no-repeat
    background-size: 25%
    z-index: 1
  &::before
    bottom: 0
    right: 0
    position: absolute
    display: block
    width: 130%
    height: 130vh
    content: ''
    background: url('../../assets/svg/f-br.svg') right bottom no-repeat
    background-size: 25%
    z-index: 1
  +v-sm
    min-height: auto
