// CTA

.product
  display: flex
  flex-flow: row wrap
  justify-content: center
  margin: 40px
  +v-sm
    flex-flow: column nowrap
  &__left,
  &__right
    width: 40%
    padding: 24px 5% 0  
    +v-md
      width: 46%
      padding: 24px 2% 0
    +v-sm
      width: 90%
      margin: 0 auto
      padding: 0
      text-align: center
    +v-xs
      width: 100%
  &__left
    text-align: right
    +v-sm
      text-align: center
  &-image
    max-width: 100%
    +v-sm
      display: block
      max-width: 90%
      margin: -2% auto 2%
    +v-xs
      max-width: 96%
      margin-top: -5%
  &__title
    color: $premium
    font-size: 1.35em
    font-weight: 500
    line-height: 1.35
    span
      display: block
      color: $lite
      font-size: .85em
      font-weight: 400
  &__text
    margin: 24px auto 40px
    font-size: 1.15em
    line-height: 1.75
    +v-sm
      margin: 40px auto
    +v-xs
      margin: 20px auto
      font-size: 1.2em