// Title

.title
  position: relative
  width: 60%
  margin-left: auto
  margin-right: auto
  padding-bottom: 100px
  color: $theme
  font-family: $serif
  font-size: 3.15em
  font-weight: 700
  line-height: 1.5
  text-align: center
  &.case
    font-size: 2.15em
  &:before
    position: absolute
    bottom: 64px
    left: calc(50% - 40px)
    right: calc(50% - 40px)
    display: block
    content: ''
    width: 80px
    height: 2px
    background-color: $intro
  &__invert
    color: $white
.subtitle
  padding: 1vh 0 2vh
  padding: 0 4px
  color: $theme
  font-family: $basic
  font-size: 1.2em
  font-weight: 500
  &.box
    margin: -8px 0 0 -8px
    padding-top: 10px
    font-size: 1.5em
.text
  margin-bottom: 4vh
  padding: 2vh 0
  color: $medium
  font-family: $basic
  font-size: 1.05em
  font-weight: 500
  line-height: 1.75
