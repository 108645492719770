// Margins
.ma
  margin-left: auto
  margin-right: auto
.mla
  margin-left: auto
.mra
  margin-right: auto

  // Width
.w-100
  width: 100%
.w-90
  width: 90%
.w-80
  width: 80%
.w-70
  width: 70%
.w-60
  width: 60%
.w-50
  width: 50%
.w-40
  width: 40%
.w-30
  width: 30%
.w-20
  width: 20%
.w-15
  width: 15%
.w-10
  width: 10%
.w-3
  width: 33.3333%
.w-4
  width: 25%

// Space
.s-10,
.s-20,
.s-30,
.s-40,
.s-50,
.s-60,
.s-70,
.s-80,
.s-90,
.s-100
  width: 100%
  clear: both
  display: block

.s-10
  height: 10px
.s-20
  height: 20px
.s-30
  height: 30px
.s-40
  height: 40px
.s-50
  height: 50px
.s-60
  height: 60px
.s-70
  height: 70px
.s-80
  height: 80px
.s-90
  height: 90px
.s-100
  height: 100px

.section
  display: block
  width: 100%
  height: auto
  min-height: 100vh
  margin: 0
  padding: 20vh 0 30vh
  clear: both
  +v-sm
    min-height: auto
    padding: 10vh 10vw 30vh

.center
  justify-content: center

.text-left
  margin: 0
  text-align: left

.text-right
  margin: 0
  text-align: right

.rowrap
  flex-flow: row wrap

.pic
  width: 100%
  height: 200px
  object-fit: cover

.boxs
  margin: 20px
  padding: 24px 24px 0
  &.border
    border: 1px solid #dd9eff

.white
  color: #fff
